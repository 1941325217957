import styled from 'styled-components';
import { MaxBoundaryContainerLarge, Section } from '../../../styles/components/containers';
import { BasicContainerBoxShadow, FlexCol, FlexRow } from '../../../styles/styles';
import { TextXLBold, TextXSItalic } from '../../../styles/components/text';

export const SentimentAnalysisContainer = styled(Section)`
  background: linear-gradient(180deg, ${({ theme }) => theme.colors.primary + '00' } 0%, ${({ theme }) => theme.colors.primary + '08' } 100%);
  min-height: 50vh;
`;

export const SentimentAnalysisInnerWrapper = styled(MaxBoundaryContainerLarge)`
  h2 {
    font-size: 48px;
  }
`;

export const SentimentContainer = styled.div`
  ${FlexCol}
  align-items: flex-start;
  gap: 20px;
  position: relative;
  margin-top: 48px;

  .sentiment-loading-spinner {
    left: 50%;
    position: absolute;
    top: 50%;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin }) {
    ${FlexRow}
    gap: 56px;
  }
`;

export const SentimentTextContainer = styled.div`
  ${BasicContainerBoxShadow}
  border-width: 1px;
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 16px;
  line-height: 26px;
  position: relative;
  padding: 30px;
  min-height: 300px;
  width: 100%;
`;

export const SentimentHeader = styled.div`
  ${FlexRow}
  gap: 10px;
  margin-bottom: 20px;

  h3 {
    ${TextXLBold}
  }

  img {
    height: 36px;
    width: auto;
  }
`;

export const SentimentFooter = styled.div`
  ${FlexCol}
  gap: 10px;
  margin-top: 20px;

  p {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
  }
`;

export const SourcesLinkContainer = styled.div`
 ${FlexRow}

 .sources-text, .view-all-sources-button {
    color: ${({ theme }) => theme.colors.grey4};
    font-size: 15px;
  }

  .view-all-sources-button {
    text-decoration: underline;
    margin-left: 5px;
  }
`;

export const DisclaimerText = styled.p`
  ${TextXSItalic}
  color: ${({ theme }) => theme.colors.grey4};
  margin: 20px 5px 0px;

  @media (${({ theme }) => theme.breakpoints.lgMin }) {
    font-size: 13px;
  }
`;
